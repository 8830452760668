import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImg from '../../components/HeaderImg';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <HeaderImg id="blutlabor" mdxType="HeaderImg" />
    </HeaderImage>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Eigenblutbehandlung`}</h1>
      <h3 {...{
        "id": "eigenblutbehandlung"
      }}>{`Eigenblutbehandlung`}</h3>
      <p>{`Die Eigenblutbehandlung ist eine Reiztherapie zur Aktivierung der körpereigenen Abwehrkräfte. Dem Patienten wird eine kleine Menge Blut aus der Armvene entnommen und nach homöopathischer Aufbereitung unmittelbar danach wieder in die Muskulatur injiziert.`}</p>
      <p>{`Das Eigenblut wird von den körpereigenen Abwehrzellen zunächst als „Fremdsubstanz / nicht dorthin gehörig“ eingestuft, systematisch auf immunologische Informationen hin untersucht und enzymatisch abgebaut.`}</p>
      <p>{`Da es sich um körpereigene Blutbestandteile handelt, ist dieser Vorgang als „Trainingseinheit“ für das Immunsystem anzusehen.`}</p>
      <p>{`Besonders bewährt hat sich die Eigenblutbehandlung als Basistherapie zur Immunstärkung bei allgemeiner Abwehrschwäche, bei Allergien / Unverträglichkeiten (vorbeugend bei Heuschnupfen ca- 2-4 Monate vor Saisonbeginn), bei chron. Hauterkrankungen, bei denen eine darm-immunologische Ursache ausgeschlossen wurde.`}</p>
      <hr></hr>
      <div style={{
        textAlign: 'center'
      }}>
    <CallToAction mdxType="CallToAction" />
      </div>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      